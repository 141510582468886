export default {
    competences: 'Can help you',
    contact: 'Contact the onboarding',
    create: 'Register my organization',
    edit: 'Edit my organization',
    follow: 'Follow the onboarding',
    help: {
        how_to: 'How to talk to Miria?',
        ideas: 'Ideas to start your search'
    },
    index: {
        placeholder: 'What do you need? Describe your situation to me and I will tell you who to contact!',
        title: 'Propel <em>your AI project</em> to success with Miria experts'
    },
    partnership_benefits: 'What do you gain from a partnership with this organization?',
    sections: {
        details: 'Details',
        intro: 'Intro',
        links: 'Links',
        offer: 'Offer',
        visit: 'Visit'
    },
    section_slugs: {
        details: 'details',
        intro: 'intro',
        links: 'links',
        offer: 'offer'
    },
    target_audiences: 'Target audiences',
    type: 'Type',
    visit: 'Visit your profile'
}