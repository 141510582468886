export default {
    competences: 'Peut vous aider à',
    contact: 'Contacter l\'organisation',
    create: 'Inscrire mon organisation',
    edit: 'Modifier mon organisation',
    follow: 'Suivre l\'organisation',
    help: {
        how_to: 'Comment parler à Miria?',
        ideas: 'Idées pour débuter votre recherche'
    },
    index: {
        placeholder: 'De quoi as-tu besoin? Décris-moi ta situation et je t\'indiquerai à qui t\'adresser!',
        title: 'Propulsez <em>votre projet d\'IA</em> vers le succès avec les experts Miria'
    },
    partnership_benefits: 'Que retirez-vous d\'un partenariat avec cette organisation?',
    sections: {
        details: 'Détails',
        intro: 'Intro',
        links: 'Liens',
        offer: 'Offre',
        visit: 'Visiter'
    },
    section_slugs: {
        details: 'details',
        intro: 'intro',
        links: 'liens',
        offer: 'offre'
    },
    target_audiences: 'Publics cibles',
    type: 'Type',
    visit: 'Visiter votre fiche'
}